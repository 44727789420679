<template>
  <div id="app" class="app-container">
    <HeaderComponent />
    <transition
        name="fade"
        mode="out-in"
    >
      <router-view :key="$route.path" />
    </transition>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
.app-container {
  min-height: 100vh; /* 确保容器高度至少为视口高度 */
  background-color: #ffffff; /* 白色背景 */
  color: #000000; /* 黑色文字 */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

/* 可选：如果 Header 和 Footer 组件背景色较暗，可以调整它们的配色 */
header, footer {
  background-color: #f8f8f8; /* 淡灰色背景 */
  color: #333333; /* 深灰色文字 */
}
</style>
