<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-logo">
        <strong>浙江盛世文脉科技有限公司</strong>
      </div>
      <div class="footer-info">
        <p class="footer-copy">&copy; 2024 浙江盛世文脉科技有限公司. 版权所有.</p>
        <p class="footer-record">浙ICP备2024133350号-1</p>
        <p class="footer-email">联系邮箱：<a href="mailto:zjpcctc@163.com">zjpcctc@163.com</a></p>
      </div>
    </div>
<!--    <div class="footer-socials">-->
<!--      <p>关注我们：</p>-->
<!--      <div class="social-icons">-->
<!--        <a href="#" class="social-icon">FB</a>-->
<!--        <a href="#" class="social-icon">LinkedIn</a>-->
<!--        <a href="#" class="social-icon">WeChat</a>-->
<!--      </div>-->
<!--    </div>-->
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer {
  background: #f8f9fa; /* 淡灰色背景 */
  color: #333; /* 深色文本 */
  padding: 20px 0;
  font-family: Arial, sans-serif;
  border-top: 2px solid #e1e1e1; /* 添加顶部边框 */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.footer-logo {
  font-size: 20px;
  font-weight: bold;
  color: #004080; /* 深蓝色 */
}

.footer-info {
  text-align: right;
  font-size: 14px;
}

.footer-info p {
  margin: 5px 0;
}

.footer-record {
  font-size: 12px;
  color: #777;
}

.footer-email a {
  color: #004080;
  text-decoration: none;
}

.footer-email a:hover {
  text-decoration: underline;
}

.footer-socials {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.footer-socials p {
  margin-right: 20px;
  font-size: 14px;
  color: #333;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 16px;
  background-color: #004080;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
}

.social-icon:hover {
  background-color: #003366;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-info {
    margin-top: 10px;
  }

  .footer-socials {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    gap: 10px;
  }
}
</style>
