<template>
  <div class="products-services">
    <section class="section-intro">
      <h1 class="section-title" v-text="sectionIntroTitle"></h1>
      <!-- <p class="section-description" v-text="sectionIntroDescription"></p> -->
    </section>

    <!-- 数字化文物保护与修复 -->
    <section class="section-category section-category-1">
      <h2 class="category-title" v-text="category1Title"></h2>
      <p class="category-description" v-text="category1Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard1Title1"></h3>
          <p v-text="serviceCard1Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard1Title2"></h3>
          <p v-text="serviceCard1Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard1Title3"></h3>
          <p v-text="serviceCard1Description3"></p>
        </div>
      </div>
    </section>

    <!-- 高新材料仿真孪生与修复 -->
    <section class="section-category section-category-2">
      <h2 class="category-title" v-text="category2Title"></h2>
      <p class="category-description" v-text="category2Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard2Title1"></h3>
          <p v-text="serviceCard2Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard2Title2"></h3>
          <p v-text="serviceCard2Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard2Title3"></h3>
          <p v-text="serviceCard2Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard2Title4"></h3>
          <p v-text="serviceCard2Description4"></p>
        </div>
      </div>
    </section>

    <!-- 文博元宇宙智能展陈 -->
    <section class="section-category section-category-3">
      <h2 class="category-title" v-text="category3Title"></h2>
      <p class="category-description" v-text="category3Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard3Title1"></h3>
          <p v-text="serviceCard3Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title2"></h3>
          <p v-text="serviceCard3Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title3"></h3>
          <p v-text="serviceCard3Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title4"></h3>
          <p v-text="serviceCard3Description4"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title5"></h3>
          <p v-text="serviceCard3Description5"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title6"></h3>
          <p v-text="serviceCard3Description6"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard3Title7"></h3>
          <p v-text="serviceCard3Description7"></p>
        </div>
      </div>
    </section>

    <!-- 文物智能化安全管理 -->
    <section class="section-category section-category-4">
      <h2 class="category-title" v-text="category4Title"></h2>
      <p class="category-description" v-text="category4Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard4Title1"></h3>
          <p v-text="serviceCard4Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard4Title2"></h3>
          <p v-text="serviceCard4Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard4Title3"></h3>
          <p v-text="serviceCard4Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard4Title4"></h3>
          <p v-text="serviceCard4Description4"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard4Title5"></h3>
          <p v-text="serviceCard4Description5"></p>
        </div>
      </div>
    </section>

    <!-- 大数据与人工智能的创新应用 -->
    <section class="section-category section-category-5">
      <h2 class="category-title" v-text="category5Title"></h2>
      <p class="category-description" v-text="category5Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard5Title1"></h3>
          <p v-text="serviceCard5Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard5Title2"></h3>
          <p v-text="serviceCard5Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard5Title3"></h3>
          <p v-text="serviceCard5Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard5Title4"></h3>
          <p v-text="serviceCard5Description4"></p>
        </div>
      </div>
    </section>

    <!-- 文化学术科研与教育支持 -->
    <section class="section-category section-category-6">
      <h2 class="category-title" v-text="category6Title"></h2>
      <p class="category-description" v-text="category6Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard6Title1"></h3>
          <p v-text="serviceCard6Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard6Title2"></h3>
          <p v-text="serviceCard6Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard6Title3"></h3>
          <p v-text="serviceCard6Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard6Title4"></h3>
          <p v-text="serviceCard6Description4"></p>
        </div>
      </div>
    </section>

    <!-- IP运营和宣传推广 -->
    <section class="section-category section-category-7">
      <h2 class="category-title" v-text="category7Title"></h2>
      <p class="category-description" v-text="category7Description"></p>
      <div class="services">
        <div class="service-card">
          <h3 v-text="serviceCard7Title1"></h3>
          <p v-text="serviceCard7Description1"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard7Title2"></h3>
          <p v-text="serviceCard7Description2"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard7Title3"></h3>
          <p v-text="serviceCard7Description3"></p>
        </div>
        <div class="service-card">
          <h3 v-text="serviceCard7Title4"></h3>
          <p v-text="serviceCard7Description4"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ProductsServicesPage",
  data() {
    return {
      sectionIntroTitle: "我们致力于通过领先的数字化技术和创新解决方案，推动文物保护与文化传承。",
      // sectionIntroDescription: "我们致力于通过领先的数字化技术和创新解决方案，推动文物保护与文化传承。",
      category1Title: "一. 数字化文物保护与修复",
      category1Description: "基于专业的技术和严谨的态度，利用激光扫描、摄影测量等技术，对文物进行高精度三维扫描并创建数字模型，留存高保真数字资源，为每件文物建立详尽的数字档案，用于文物的分析、修复和数字化展示等多重场景。",
      serviceCard1Title1: "1. 3D扫描与建模",
      serviceCard1Description1: "利用业界领先的激光扫描、摄影测量等多种技术对文物进行高精度三维扫描，专业建模师将采集数据重建为超精细的三维模型，实现文物的数字化孪生，为研究分析、修复展示及教育提供生动直观的素材。",
      serviceCard1Title2: "2. 虚拟修复",
      serviceCard1Description2: "通过数字化技术模拟文物修复全过程，使得文物即使在物理损坏的情况下，也能以虚拟方式恢复其原貌。",
      serviceCard1Title3: "3. 文物数字档案",
      serviceCard1Description3: "为文物建立详尽的数字档案，记录其当前状态、历史痕迹以及修复过程等，方便后续的管理和保护。",
      category2Title: "二. 高新材料仿真孪生与修复",
      category2Description: "运用3D打印技术实现文物精确复制、高效修复、虚拟复位及跨地域展出，助力文物保护与传播。创新采用复合材料（如玻璃纤维、陶瓷复合材料等）、树脂、聚乳酸（PLA）等新材料，进行文物的高还原复制，打印并修复文物的缺失或损坏部分。采用三维打印技术高效省时，尤其是陶瓷3D打印技术已广泛应用于修复陶瓷文物，比起传统的文物复制与修复方式，有着十分显著的优点。",
      serviceCard2Title1: "1. 文物复制和修复",
      serviceCard2Description1: "利用3D打印技术，实现文物的毫米级精确复制与精细化修复。不仅可加速文物修复的进程，显著提升修复工作的精确度与质量，复制的高保真还原品还能在展览中得以广泛应用，既可以展示文物的原始风貌，又有效减少了对珍贵文物本体的物理接触与潜在损伤，确保了文化遗产的长远保存。采用三维打印技术高效省时，尤其是陶瓷3D打印技术已广泛应用于修复陶瓷文物，比起传统的文物复制与修复方式，有着十分显著的优点。",
      serviceCard2Title2: "2. 高新材料仿真孪生",
      serviceCard2Description2: "基于高新材料既能保证高强度，又具备与原文物相似的物理和化学特性。具备多种仿真材料可选，包括但不限于：（1）复合材料：采用复合材料（如玻璃纤维、陶瓷复合材料等）打印文物的缺失部分，这些材料既能保证高强度，又可具备与原文物相似的物理和化学特性。（2）树脂与塑料：在某些文物修复中，树脂、聚乳酸（PLA）等材料可以打印文物的损坏部分，形态与原文物高度相似，并且具备较高的可塑性。（3）陶瓷材料：陶瓷3D打印技术已广泛应用于修复陶瓷文物，打印出的陶瓷零部件可以与原文物的风格、材质等进行匹配，达到理想的修复效果。",
      serviceCard2Title3: "3. 不可移动文物的跨地区展出",
      serviceCard2Description3: "针对体量庞大或环境敏感型的不可移动文物，通过3D打印复制服务，使得珍贵文物能够以高仿真度的复制品形态，在全国其他不同区域以及全球范围内进行巡回展出。这能有效打破地域限制，让更多人有机会近距离感受文化遗产的魅力，实现文物价值的最大化传播。",
      serviceCard2Title4: "4. 流失文物虚拟复位",
      serviceCard2Description4: "针对各类由于历史原因流失在海外的文物，采用三维扫描、数据建模、图像识别、大数据分析、虚拟拼接等数字化技术，结合历史文献档案与文史、考古、古建领域考古专家的指导，能精确复原其原貌，实现文物在虚拟空间的“数字化回归”。后续可通过数字博物馆、云展览等形式，使这些流散的文化瑰宝得以在数字世界中“复位”。",
      category3Title: "三. 文博元宇宙智能展陈",
      category3Description: "文博元宇宙智能展陈将融合全息线上博物馆与沉浸式线下展览，打造虚实结合的文化体验，让观众随时随地穿越时空，感受历史文化魅力。",
      serviceCard3Title1: "1. 文博展陈设计",
      serviceCard3Description1: "致力于提供全面的专题展览解决方案，结合实物展示、多媒体内容以及数字内容交互程序的设计与实施，涵盖实地展览设计、数字内容制作、影视动画、工程及系统开发等多方面业务，为教育机构和公众提供丰富的文化学习资源，增强展览的教育性和趣味性。",
      serviceCard3Title2: "2. 全息线上博物馆",
      serviceCard3Description2: "构建多平台兼容的数字孪生博物馆，提供360°全景漫游、虚拟讲解等服务，通过数字化科技扩大文物的线上展示空间，让全球观众不受地域限制，随时随地享受沉浸式的观展体验。",
      serviceCard3Title3: "3. 沉浸式线下展览",
      serviceCard3Description3: "将高精度的博物馆、大遗址、石窟寺、古建筑的数字孪生数据，融合MR、全息投影、裸眼3D、体感互动、数字投影等技术与实体展陈结合，在展览中展示文物的历史故事和背景，打造虚实结合的互动体验，让观众在虚拟与现实交错的“文化元宇宙”中穿越时空，增强互动参与感，感受历史文化的魅力。",
      serviceCard3Title4: "4. 线上线下融合的展览平台",
      serviceCard3Description4: "构建云基数据互联平台，确保线上线下的展览内容实时同步与数据流通。配备智能化展示设备，实时更新展品信息、提供多语言解说，也便于快速实施展品信息变动、活动调度及反馈搜集，为观众提供连贯一致且高效的展览体验。",
      serviceCard3Title5: "5. 智慧导览系统",
      serviceCard3Description5: "为展览定制打造支持语音导航、AI虚拟人导览、AR增强现实导览等多种功能的导览系统，让观众在轻松愉快的氛围中探索博物馆的每一个角落。支持收集观众反馈数据，为博物馆的运营和服务优化提供有力支持，进一步提升观众参观体验。",
      serviceCard3Title6: "6. 文创产品与旅游开发",
      serviceCard3Description6: "依托文化遗产数字资源，开发文创产品，进行文化旅游与研学产品等开发，提升文化遗产的社会影响力和经济效益。",
      serviceCard3Title7: "7. 创意文博应用开发",
      serviceCard3Description7: "推出以文物为灵感的互动应用程序与教育游戏，例如文物复原拼图、历史角色探险游戏、数字收藏品等，增加线上互动，提高商业转化率。",
      category4Title: "四. 文物智能化安全管理",
      category4Description: "文物智能化安全管理业务涵盖智能管理平台、移动端管理工具、数据管理安防体系及智慧展览管理，实现对文物及其数字资源的全方位智能化监控、维护、管理与保护，确保文物数据安全并提升管理效能。",
      serviceCard4Title1: "1. 文物智能管理平台",
      serviceCard4Description1: "结合物联网技术，建立文物的智能管理平台，实现对文物的实时监控、维护和管理。集成多种传感器和监控设备，对文物的环境参数（如温度、湿度、光照等）进行实时监测，一旦发现异常立即触发预警机制，确保文物处于最佳保存状态。",
      serviceCard4Title2: "2. 移动端管理工具",
      serviceCard4Description2: "通过开发手机或平板等设备上的移动应用，为文物管理人员提供便捷的文物信息查看和管理功能。管理人员可以随时随地查看文物的当前状态、维护记录、预警信息等，并进行相应的信息更新和管理操作，提高工作效率。",
      serviceCard4Title3: "3. 数据管理安防体系",
      serviceCard4Description3: "采用高效、安全存储、检索保护技术，确保文物数字资产的高效、安全管理和异地灾备。通过采用加密技术、访问控制、数据备份等多种手段，有效防范数据泄露、篡改和丢失等风险，保障文物数字资产的安全性和完整性。",
      serviceCard4Title4: "4. 数据安全与版权保护",
      serviceCard4Description4: "利用区块链加密、数字水印、文物数据确权追溯等技术，保障文物数据的版权和所有权，防止数据流转过程中未授权使用等情况发生。",
      serviceCard4Title5: "5. 智慧展览管理",
      serviceCard4Description5: "可根据用户实际需求，搭建包含展览策划、场馆运营与观众互动等功能的一站式管理系统。该系统能够智能化分析观众行为数据，如参观路径、停留时间、互动反馈等，从而精准把握观众需求和偏好，实现展览活动的智能化调度与个性化服务。同时，系统还能提供丰富的展览策划工具和场馆运营支持功能，助力提升参观体验和管理效率。",
      category5Title: "五. 大数据与人工智能的创新应用",
      category5Description: "利用大数据与AI技术，创新构建文化遗产数字资源库，实现文物智能监测、深度分析与个性化教育体验服务。",
      serviceCard5Title1: "1. 文化遗产数字资源库建设",
      serviceCard5Description1: "采集并整合文物的高清图像，高保真三维模型与详尽的文献背景等全面综合的资料，进行大数据规范编制、平台建设和大数据计算等工作，构建完善的文化遗产数字资源库，致力于推动文化资源的创造性转化与创新性发展。",
      serviceCard5Title2: "2. 文保预测性监测系统",
      serviceCard5Description2: "为历史遗迹与珍贵文物量身定制全方位的监测与保护策略，并搭建预测性维护警示系统。采用最前沿的物联网(IoT)与人工智能(AI)技术，通过部署精密传感器网络，实时监测温湿度、光照、振动等环境因素，AI算法即时分析数据，预警潜在风险。",
      serviceCard5Description3: "依托强大的数据分析平台，对大量文物数字化信息进行分析处理，深入挖掘文物数据背后的历史脉络、艺术美学、社会影响与文化价值。有效辅助考古发掘及文物修复，并为文物的深度开发利用与多元化文创产品的孵化提供科学依据。",
      serviceCard5Title3: "3. 文物信息大数据分析处理",
      serviceCard5Title4: "4. 个性化文化遗产教育服务",
      serviceCard5Description4: "通过智能算法模型，精准描绘受众画像，指导文创产品定位与市场策略，提供个性化的文化遗产教育体验服务，如XR互动教学、3D打印课程等，确保每项创意都能精准触达目标群体，实现文化与市场的高效对接，促进文化传承与经济价值的双重提升。",
      category6Title: "六. 文化学术科研与教育支持",
      category6Description: "盛世文脉凭借顶尖科研团队，提供文物保护技术培训与咨询，策划考古研学与社会教育活动，并携手合作方与国内外专业学术科研机构开展深度合作，培养公众科学素养与人文精神，助力考古资源的有序开放共享和参与主体的多元化。",
      serviceCard6Title1: "1. 顶尖科研团队",
      serviceCard6Description1: "盛世文脉科技的初创团队汇聚了当前国内文物数字化领域的精英力量，致力于在交叉学科视角下持续研发行业领先的文物保护与利用技术，以高精尖数字化技术推动行业技术和理念的进步。",
      serviceCard6Title2: "2. 专业培训与咨询服务",
      serviceCard6Description2: "盛世文脉科技拥有一流学术领域专家和一线技术骨干组成的团队，可为从业人员及爱好者提供行业最新的理论知识与实践专业培训课程，提升其保护技能和技术水平，持续为文博行业培养输送新型人才，并提供文物保护相关的技术咨询、项目申报和解决方案支持。",
      serviceCard6Title3: "3. 合作推动与交流平台",
      serviceCard6Description3: "盛世文脉和省内外高校、文博单位、企业、科研院所以及全球考古文博机构、国际组织等均保持密切的合作关系，联合合作方共同搭建历史学、考古学、信息技术、材料学等多学科的行业知识共享平台，通过线上、线下多渠道不定期开展关于学术前沿、人才培养、机构建设等方面的经验分享，携手推动跨学科、跨专业、跨领域文物保护深入协同合作。",
      serviceCard6Title4: "4. 考古研学产品定制",
      serviceCard6Description4: "基于客户自身的资源价值特点，推出定制化的专属研学产品和服务，针对不同年龄层次、知识背景的对象，开发设计不同类型富有启发性、探索性、实践性、体验性的系列专题课程，提供参观、课堂、实践、模拟、竞赛以及线上等各种形式的课程服务。在安全性、研究性、公益性、规范性原则之下开展考古研学活动，以精心打造的优质课程内容与高质量配套服务有效转化考古成果和历史研究成果，使广大公众获得深度体验和实践教育，更好的促进考古研学行业发展以及满足社会公众的期盼与要求。",
      category7Title: "七. 文化IP运营和宣传推广",
      category7Description: "通过数字化转型商业化策略，将文化遗产转化为具有持久价值的知识产权。这一过程赋予文化遗产新的价值，使文化IP的独特元素成为可识别、可追踪、可变现的资产。通过品牌营销推广和产业化发展，这些宝贵的文化资源得以焕发新的活力。盛世文脉科技在文化资源商业化过程中，高度重视文化IP的保护。对文化失真、知识产权纠纷、投入产出不平衡等问题进行严格把控，通过提前界定、合作监督和质量检验，为文化IP授权保驾护航。",
      serviceCard7Title1: "1. 多人VR、多类型互动体验",
      serviceCard7Description1: "借助高精度的3D扫描和VR技术，在虚拟空间生动再现历史原貌，让体验者通过互动游戏形式穿越时空。比如多人VR沉浸式体验能让多名用户同时进入虚拟场景，实现实时互动和交流，创造独特的社交体验。能实现虚拟导览、历史场景重现、文物修复体验、文化故事演绎等多种互动形式，满足不同用户需求。通过将严谨的文化内容与趣味性游戏体验相结合，既确保知识传递的准确性，又增强学习的趣味性，同时突破地理限制，实现文化体验的跨区域共享。",
      serviceCard7Title2: "2. 游戏与影视产业应用",
      serviceCard7Description2: "文化遗产与游戏与影视产业的结合具有巨大的商业潜力。文化遗产中的建筑、历史人物和神话传说等元素可以被提取，并融入到游戏与影视的世界观、角色、场景设计中。盛世文脉作为专业团队，严格把控游戏化过程中的文化失真、知识产权纠纷、投入产出不平衡等问题，通过提前界定、合作监督和质量检验，为文化IP授权保驾护航。",
      serviceCard7Title3: "3. 高端研学课程与教育衍生服务",
      serviceCard7Description3: "依托盛世文脉科技的专业学术资源，针对不同人群，分别设计趣味性和深度化等定制化课程，满足不同高端群体需求，能系统展示传统文化精髓，提供结合多种学习形式的深入体验。并且随着教育领域对创新教学工具的需求增加，以及对文化遗产教育重视程度的提升，新型的3D打印文化教具市场具有巨大的潜力，比如古建筑构件与等比缩小模型等。学校、博物馆、文化机构等都可能成为3D打印教育产品或服务的潜在客户。",
      serviceCard7Title4: "4. 综合性文旅IP数字装置",
      serviceCard7Description4: "结合文化遗产数字资源，集三维打印技术、声光技术等数字新技术于一体的综合性数字化装置，能帮助商场与景点增强消费体验，吸引消费群体，促进销售，因此在商场布展、旅游展览中有极高的商业需求性。"
    };
  }
};
</script>

<style scoped>
.products-services {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 0 2rem;
}

.section-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #004080;
}

.section-description {
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #666;
}

.section-category {
  margin-bottom: 4rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 3rem 2rem;
  position: relative;
}

/* 为每个部分添加不同的背景图 */
.section-category-1 {
  background-image: url('../assets/products/img1.png');
}

.section-category-2 {
  background-image: url('../assets/products/img2.png');
}

.section-category-3 {
  background-image: url('../assets/products/img3.png');
}

.section-category-4 {
  background-image: url('../assets/products/img4.png');
}

.section-category-5 {
  background-image: url('../assets/products/img5.png');
}

.section-category-6 {
  background-image: url('../assets/products/img6.png');
}

.section-category-7 {
  background-image: url('../assets/products/img7.png');
}

/* 为每个部分添加半透明的遮罩层 */
.section-category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  /* 调整透明度，增加背景亮度 */
  z-index: 1;
}

/* 标题和描述的样式 */
.category-title {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  /* 白色文字 */
  margin-top: 1.5rem;
  position: relative;
  z-index: 2;
  /* 确保文字在遮罩层上 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  /* 为标题添加阴影，增强对比 */
}

.category-description {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: #f1f1f1;
  /* 浅灰色文字 */
  position: relative;
  z-index: 2;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  /* 为描述添加阴影，增加可读性 */
}

/* 服务部分的布局 */
.services {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  /* 设置卡片间的间距 */
  justify-content: center;
  /* 居中对齐卡片 */
}

/* 单个服务卡片的样式 */
.service-card {
  flex: 1 1 300px;
  /* 卡片最小宽度 300px，允许伸缩 */
  min-width: 250px;
  /* 最小宽度 */
  max-width: 350px;
  /* 最大宽度 */
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* 添加过渡效果 */
}

.service-card h3 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #004080;
  margin-bottom: 1rem;
}

.service-card p {
  font-size: 1rem;
  color: #555;
}

/* 鼠标悬停效果 */
.service-card:hover {
  transform: translateY(-5px) scale(1.02);
  /* 提升并放大卡片 */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  /* 添加更强的阴影 */
}

@media (max-width: 768px) {
  .services {
    gap: 4vw;
    /* 小屏幕时增大间距 */
  }

  .service-card {
    flex: 1 1 100%;
    /* 在小屏幕上，卡片宽度占满父容器 */
    max-width: 100%;
    /* 确保卡片宽度不超过父容器 */
  }

  .service-card h3 {
    font-size: 1.2rem;
    /* 小屏幕时减少标题字体 */
  }

  .service-card p {
    font-size: 0.9rem;
    /* 小屏幕时减少文本字体 */
  }
}
</style>
