<template>
  <div class="project-introduction">
    <div class="project-header">
      <section class="section-intro">
        <h1 class="section-title">依托先进的成套技术服务能力和资深的项目实施经验，我们为各类文物与文化遗产提供全方位的保护与数字化应用服务。</h1>
        <!--      <p class="section-description">我们致力于通过领先的数字化技术和创新解决方案，推动文物保护与文化传承。</p>-->
      </section>
    </div>

    <div v-for="(project, index) in projects" :key="index" class="project-card">
      <div class="project-title">
        <h2>{{ project.title }}</h2>
      </div>

      <div class="project-content">
        <p><strong>项目时间：</strong><span v-html="project.time"></span></p>
        <p><strong>项目简介：</strong><span v-html="project.introduction"></span></p>
        <p><strong>合作方：</strong><span v-html="project.partner"></span></p>
        <p><strong>技术服务支持：</strong><span v-html="project.technology"></span></p>
        <p><strong>项目进展：</strong><span v-html="project.progress"></span></p>
      </div>

      <div v-if="project.images.length > 0" class="project-images">
        <h3>项目图片与成果：</h3>
        <div class="image-gallery">
          <div v-for="(image, i) in project.images" :key="i" class="image-item">
            <img :src="image.src" :alt="image.description" />
            <p class="image-description">{{ image.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectIntroduction",
  data() {
    return {
      projects: [
        {
          title: "苏州保圣寺罗汉彩塑数字化虚拟修复项目",
          time: "2024年5月接洽；2024年7月合同签订；2024年8月外业采集工程实施。",
          introduction: "位于苏州市吴中区的 “保圣寺罗汉塑像”，为全国首批重点文物保护单位之一。为进一步科学严谨地对保圣寺罗汉彩塑进行本体修复，本项目利用激光三维扫描技术、近景摄影测量、纹理映射技术对保圣寺罗汉采集全息的数字化记录，为后续数字化展陈、利用、互动提供可持续编辑的成果数据。具体工作包括：<br>" +
            "建立罗汉彩塑永久、科学、清晰、健全的数字档案，以达到3D原真复制级别的研究档案资料；依托数字化成果，对彩塑本体病害进行调查分析，在数字成果基础之上，结合历史文献资料对彩塑进行合理的虚拟3D雕刻、上色虚拟修复，为进一步本体修复提供科学、合理的参考依据。",
          partner: "级别：全国重点文物保护单位<br>" +
            "保圣寺始建于梁天监二年(公元503年)，距今已有1500多年的历史。寺内古物馆里的塑壁罗汉文物价值极高，相传是唐代塑圣杨惠之的作品。1961年，保圣寺罗汉塑像与北京故宫、敦煌莫高窟等180处文物一起被列入第一批全国重点文物保护单位。",
          technology: "用高精度大场景激光三维扫描、近景摄影测量技术、分层式全息图像采集，采集完整度≥95%，解决了大部分彩塑狭小空间的数据缺失，结合纹理映射出具整体造像色彩三维模型。<br>" +
            "根据现状数字化成果模型，利用3D雕刻仿真技术、Substance 3D Painter从形、色方面进行虚拟雕刻上色处理，从而形成新型色彩三维数据。",
          progress: "进行中",
          images: [
            {
              src: require("@/assets/projects/img1.png"),
              description: "作业现场",
            },
            {
              src: require("@/assets/projects/img2.png"),
              description: "采集工作现场1",
            },
            {
              src: require("@/assets/projects/img3.png"),
              description: "采集工作现场2",
            },
            {
              src: require("@/assets/projects/img4.png"),
              description: "整体彩塑正射影像",
            },
            {
              src: require("@/assets/projects/img5.png"),
              description: "整体彩塑高精度素模",
            },
            {
              src: require("@/assets/projects/img6.png"),
              description: "整体彩塑素模局部",
            }
          ]
        },
        {
          title: "茅盾纪念馆数字化保护（一期）项目",
          time: "2024年5月-2024年8月",
          introduction: "茅盾纪念馆是社会科学类名人专题纪念馆，馆内收藏了茅盾留下的丰富手迹，包括书信、日记、小说、诗词、回忆录、文艺评论的创作手稿及笔记、杂抄、古诗文注释、译稿等，具文献价值和艺术价值。本公司运用先进数字化技术，完成了馆内400余件书画和书信的高清数字化建档工作，为茅盾纪念馆文物存档及未来展示、传播提供重要支撑。",
          partner: "全国重点文物保护单位、国家三级博物馆<br>茅盾纪念馆坐落在浙江省桐乡市乌镇镇观前街17号，占地面积1611平方米，建筑面积为116.47平方米，是社会科学类名人专题纪念馆。现为国家三级博物馆，截至2019年，桐乡市茅盾纪念馆有藏品数量543件/套，珍贵文物64件/套。桐乡市茅盾纪念馆于1988年1月被国务院公布为全国重点文物保护单位，1993年被中共嘉兴市委、嘉兴市人民政府命名为红色教育长廊青少年教育基地；1995年被中共浙江省委、浙江省人民政府命名为浙江省爱国主义教育基地；1996年被浙江省文物局评为浙江省文明博物馆；1999年被嘉兴市旅游局评为嘉兴市文明旅游景区（点），2009年7月被中共浙江省委党史研究室公布为浙江省党史教育基地。",
          technology: "本项目使用高精度数字化技术对矛盾纪念馆内馆藏约400件书信与书画进行数字化信息留存，采集精度为600ppi。本次数字化工作将茅盾纪念馆中书信、书画作品转化为高分辨率的数字图像，减少物理接触和损耗，从而有效保护原作。数字化文件还可以复制和备份，避免了原件丢失的风险。书画数字化后，作品可以存储在数据库中，方便进行分类、管理、检索和调阅。通过数字化版本的作品可支持高清分析、临摹和研究，在日后的陈展工作中可利用绘画软件进行展示与创作。通过虚拟现实（VR）、增强现实（AR）等技术与观众进行互动，为观众提供更丰富的观赏体验。",
          progress: "已完结",
          images: [
            {
              src: require("@/assets/projects/img7.png"),
              description: "上图：壁画、书画轨道式高精度数字化采集设备",
            },
            {
              src: require("@/assets/projects/img8.png"),
              description: "上图：数据采集核对",
            },
            {
              src: require("@/assets/projects/img9.png"),
              description: "上图：数据采集工作照",
            },
            {
              src: require("@/assets/projects/img10.png"),
              description: "上图：吴长邺葫芦图",
            },
            {
              src: require("@/assets/projects/img29.png"),
              description: "陈舜今鹦鹉图",
            }
          ]
        },
        {
          title: "重庆博物馆馆藏革命文物数字化保护项目",
          time: "2024年5月-2024年8月",
          introduction: "我公司在本项目中以重庆博物馆（重庆中国三峡博物馆）近40件（套）珍贵革命文物为主要信息采集对象，通过对文物高清二维信息和三维信息的采集，为构建革命文物数字化保护项目库和“云展览”，同时兼顾数据的存储和利用功能，最后通过基于革命文物的微视频宣传方式，打造革命文物传播展示应用典型示范案例提供基础数据资源。",
          partner: "级别：国家一级博物馆<br>重庆中国三峡博物馆（重庆博物馆）是一座集巴渝文化、三峡文化、抗战文化、移民文化和城市文化等为特色的历史艺术类综合性博物馆，是中央地方共建国家级博物馆、国家文化和科技融合示范基地、首批国家一级博物馆、全国最具创新力博物馆、国家文物局重点科研基地、全国爱国主义教育示范基地、全国科普教育基地、全国青少年教育基地、海峡两岸文化交流基地、全国古籍重点保护单位等。其前身为1951年成立的西南博物院，1955年因西南大区撤销更名为重庆市博物馆，2000年为承担三峡文物保护工程的大量珍贵文物抢救、展示和研究工作，经国务院办公厅批准设立重庆中国三峡博物馆。",
          technology: "项目首先选取36件（套）馆藏二维革命文物，使用全画幅高清数码相机针对不同类型和形态的文物拍摄多角度高精度照片，精度达到出版级并保存数字底片；其次，选取3件（套）三维馆藏革命文物，通过激光扫描仪建立文物的高精度点云数据，对点云数据进行加工生成3D数字模型；对文物进行高精度拍摄，获取文物表面高精度纹理照片，然后与之前得到的3D模型进行贴图处理，通过后期艺术加工，将扫描的文物还原为高精度3D虚拟文物，可以全方位展示观察。3D文件保存格式为OBJ+STL+PI3三种，可支持多种3D软件的应用。",
          progress: "已完结",
          images: [
            {
              src: require("@/assets/projects/img11.png"),
              description: "上图：壁画、书画轨道式高精度数字化设备现场设备调试工作照",
            },
            {
              src: require("@/assets/projects/img12.png"),
              description: "上图：平面文物图像采集工作照",
            },
            {
              src: require("@/assets/projects/img13.png"),
              description: "上图：摄影测量三维数据采集工作照",
            },
            {
              src: require("@/assets/projects/img14.png"),
              description: "",
            },
            {
              src: require("@/assets/projects/img15.png"),
              description: "上图：项目成果图",
            }
          ]
        },
        {
          title: "《石窟壁画亿级像素数字图像自动拼接方法与软件》优化与技术提升项目",
          time: "2024年11月-2025年11月",
          introduction: "本项目拟开发一套高效、稳定的壁画数字化系统，支持大规模数据处理、点云处理、拼接图像处理、项目管理服务和日志采集功能，为敦煌研究院世界文化遗产的保护与研究提供技术支持。",
          partner: "级别：世界文化遗产、国家一级博物馆<br>" +
            "敦煌研究院是负责世界文化遗产敦煌莫高窟、天水麦积山石窟、永靖炳灵寺石窟，全国重点文物保护单位瓜州榆林窟、敦煌西千佛洞、庆阳北石窟寺管理的综合性研究型事业单位，也是国家古代壁画与土遗址保护工程技术研究中心、古代壁画保护国家文物局重点科研基地、甘肃省敦煌文物保护研究中心的依托单位。2019年8月19日，习近平总书记视察敦煌研究院并发表重要讲话，提出了把敦煌研究院建设成为世界文化遗产保护的典范和敦煌学研究的高地的要求。",
          technology: "本公司对软件所涉及的多视图三维重建技术，SIFT 特征提取技术、基于三角形约束的动态增加匹配对技术、全局式初始相机姿态估计技术等关键技术进行算法优化和提升，使用Reality Capture软件适合多类型壁画自动拼接，并提升拼接效率和质量，并提交软件的操作方法和流程说明书以及测试报告。",
          progress: "进行中",
          images: []
        },
        {
          title: "乃朗寺文物陈列展示项目",
          time: "2024年4月30日-2024年5月1日",
          introduction: "藏传佛教文化是西藏优秀传统文化的重要组成部分，在历史长河中沉积了众多见证祖国统一不可分割和民族团结的历史文物，是西藏文物不可或缺的一部分。2024年4月底，位于西藏自治区的乃朗寺有一批唐卡文物需要加急实施数字化保护，共计36件/套，另有馆藏佛像1尊，佛台1件。公司迅速对这批寺院的重要可移动文物进行数字化采集，为乃朗寺系统阐释藏传佛教噶玛噶举派中国化历史进程的发展主线和文物陈列馆展示利用工作提供了宝贵资料数据，助力其打造优秀宗教文化遗产的数智化展示平台，推动寺庙藏可移动文物的规范化、科学化管理。",
          partner: "乃朗寺为西藏自治区级文保单位，执持噶玛噶举派传承，坐落于卫藏中心拉萨市西面约六十公里处，殊胜密严刹土堆龙乃朗谷中心。由第一世夏玛巴多丹扎巴森格于铁鸡年（即公元 1321 年创建），全称“楚乃朗寺”，又名“佛子修行地”。迄今已有700的悠久历史，为历代巴沃活佛的主寺。第二世巴沃·祖拉成瓦著作《贤者喜宴》是西藏重要历史典籍，已经成为了解与研究藏族历史文化的珍贵史料。目前寺院由第十一世巴滇巴沃仁波切主持，是西藏自治区第一座“绿色寺院”“零碳寺院”，为西藏区域生态保护标杆示范单位。<br>截至目前，该寺有13件文物，其中一级文物2件，二级文物6件，三级文物5件。有14项非遗代表性项目，其中自治区12项（冈仓音乐、冈仓金刚舞、冈仓彩砂坛城绘制技艺、藏文书法——噶宁噶赤、冈仓古朵制作技艺、那若六法瑜伽健身技艺、冈仓禅修习俗、冈仓“让江巴”学位授予习俗、冈仓甘露加持仪式、冈仓经籍传承习俗、冈仓五本尊仪轨、五大库传承习俗），县级2项（乃朗寺酥油花、乃朗寺嘉林吹奏法）。<br>2023年8月30日，乃朗寺正式挂牌西藏自治区图书馆乃朗寺分馆、西藏自治区古籍保护中心乃朗寺古籍馆、西藏自治区非遗保护中心乃朗寺分馆和乃朗寺文物陈列馆。其中乃朗寺古籍馆自2016年运作至今，目前共有110函1600余部，工作人员10名，致力于噶举传承古籍数字化保护工作。",
          technology: "因该项目属于突发抢救性的工作任务，现场所携带设备较为有限，且采集时间比较紧张。现场使用了手持相机并搭配使用闪光灯的方式进行采集。拍摄时发现唐卡局部位置受材质影响有较为明显的反光，尝试将唐卡平铺在地面上，两端各布置一盏闪光灯，工作人员手持相机环绕唐卡进行拍摄，除使用镜头垂直于画面进行拍摄外，另补充拍摄了部分镜头与画面略有倾斜角度的图像。现场使用RC对图像进行初步检查，基本能够满足采集需求，未见有大面积反光，少部分唐卡图像局部位置略有反光痕迹，但可以通过后期工作对该位置进行图像的替补和修正。",
          progress: "已完结",
          images: [

            {
              src: require("@/assets/projects/img16.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img17.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img18.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img19.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img20.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img21.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img22.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img23.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img24.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img25.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img26.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img27.png"),
              description: " ",
            },

            {
              src: require("@/assets/projects/img28.png"),
              description: " ",
            },
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.section-intro {
  text-align: center;
  margin-bottom: 3rem;
}

.project-introduction {
  padding: 20px;
  background-color: #f4f4f4;
}

.project-header {
  text-align: center;
  margin-bottom: 40px;
}

.project-header h1 {
  font-size: 2.5rem;
  color: #004080;
}

.intro-description {
  font-size: 1.2rem;
  color: #666;
}

.project-card {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-title h2 {
  font-size: 1.8rem;
  color: #004080;
}

.project-duration {
  font-size: 1rem;
  color: #888;
}

.project-content p {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
}

.project-images {
  margin-top: 30px;
}

.image-gallery {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-gallery img {
  width: 100%;
  max-width: 320px;
  /* 最大宽度限制 */
  height: auto;
  /* 高度根据宽度自动调整 */
  aspect-ratio: auto;
  /* 根据图片原始比例自适应 */
  object-fit: contain;
  /* 保证图片不会拉伸或裁剪 */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  /* 图片未填充时，背景色展示 */
  transition: transform 0.3s ease;
  /* 添加图片放大动画 */
}

.image-gallery img:hover {
  transform: scale(1.05);
  /* 鼠标悬停时，图片轻微放大 */
}

@media (max-width: 768px) {
  .image-gallery {
    justify-content: center;
    /* 在小屏幕上居中显示图片 */
  }

  .image-gallery img {
    max-width: 100%;
    /* 小屏幕下图片宽度为100% */
    height: auto;
    /* 高度自适应 */
    aspect-ratio: auto;
    /* 保持图片比例 */
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .image-gallery img {
    max-width: 48%;
    /* 中等屏幕下，图片宽度为48% */
    aspect-ratio: auto;
    /* 保持图片原有比例 */
  }
}
</style>
