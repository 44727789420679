<template>
  <section class="about-section">
    <div class="container">
      <div class="about-scope1">
        <h2 class="about-title">关于我们</h2>

        <!-- 图片和文字容器 -->
        <div class="about-content">
          <!-- 图片部分 -->
          <div class="about-image-container" @click="showModal">
            <img src="@/assets/about-image-low-quality.png" alt="关于我们图片" class="about-image" />
          </div>

          <!-- 文字部分 -->
          <div class="about-text">
            <p class="about-description">
              浙江盛世文脉科技有限公司（Zhejiang Prosperous Cultural Context Technology Co.,
              Ltd.），通过多维度的数字化科技赋能文脉传承、创新与可持续保护，以文化数字资源知识产权管理、文化遗产IP的专业性市场化运营为重点发展方向，拥有先进的成套技术服务能力和资深的项目实施经验，持续创造、管理、扩展IP价值，致力于让古老文化与现代生活创新交融，在推动历史文化遗产系统性数字化保护领域取得重大进展并持续探索。
            </p>
            <p class="about-description">
              公司立足中华民族伟大历史实践和新时代科技发展潮流，坚定文化自信，以文脉传承为理念，以专业践行为使命，已建立起一支涵盖工程技术、人文社科、自然科学等交叉学科的综合性队伍，依托顶尖科研能力和学术成果支撑，不断引进专业力量丰富技术生态体系，构建全方位、立体式、多维度的文物保护利用新体验、新场景、新路径，以创新科技助力文化遗产的深度数字化和“文物+”成果转化。
            </p>
            <p class="about-description">
              公司已形成全套的文化资源保护和利用的科技解决方案，拥有行业领先的专有技术与实力精干的人才支撑，同全球化的优秀团队、机构建立了长期稳固的合作互信关系及紧密良好的协作机制，促进技术研发与行业文化深层次交融，推动科技创新与文化传承，用数字技术让文化遗产以更加多元的方式活化传承，提升与公众的交互和联结，加快文化与科技深度融合，推动文化遗产面向未来、永续发展，焕发时代新活力！
            </p>
          </div>
        </div>
      </div>

      <div class="about-scope">
        <h2 class="about-title">业务矩阵</h2>
        <div class="scope-modules">
          <div class="scope-module" v-for="(module, index) in modules" :key="index">
            <h4>{{ module.title }}</h4>
            <p>{{ module.content }}</p>
          </div>
        </div>
      </div>

      <div class="about-scope">
        <h2 class="about-title">核心价值</h2>
        <div class="about-cards">
          <div class="card" v-for="(item, index) in cards" :key="index">
            <h3>{{ item.title }}</h3>
            <p v-html="item.description"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- 模态框 -->
    <div v-if="isModalVisible" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <img :src="highQualityImage" alt="放大查看图片" class="modal-image" ref="modalImage" @wheel="zoomImage" />
        <div class="zoom-controls">
          <button @click="zoomIn">放大</button>
          <button @click="zoomOut">缩小</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isModalVisible: false, // 控制模态框的显示与隐藏
      lowQualityImage: '@/assets/about-image-low-quality.png', // 低清晰度图片
      highQualityImage: require('@/assets/about-image.png'), // 高清晰度图片
      zoomLevel: 1, // 图片的缩放等级
      modules: [
        { title: "数字化文物保护与修复", content: "利用激光扫描、摄影测量等先进技术，实现文物的数字化保存与修复，确保文物的文化价值和历史传承。" },
        { title: "高新材料仿真孪生与修复", content: "运用3D打印技术与新型材料，实现文物的精确复制与高效修复，突破传统修复方式的局限。" },
        { title: "文博元宇宙智能展陈", content: "结合全息线上博物馆与沉浸式线下展览，打造虚实结合的文化体验，让观众感受历史文化魅力。" },
        { title: "文物智能化安全管理", content: "通过智能化平台、移动端工具和数据安防系统，实现文物的全面安全管理与保护。" },
        { title: "大数据与人工智能的创新应用", content: "结合大数据与AI技术，推动文化遗产数字化与智能监测，为文物保护与教育提供精准服务。" },
        { title: "文化学术科研与教育支持", content: "凭借顶尖科研团队和合作伙伴，推动文物保护领域的技术创新与教育发展。" },
        { title: "IP运营和宣传推广", content: "通过数字化转型商业化文化遗产，赋予其新的经济价值，推动文化IP的产业化。" }
      ],
      cards: [
        { title: "我们的使命", description: "科技赋能 融合创新 <br><br> 全领域焕活文化遗产保护" },
        { title: "我们的愿景", description: "传承历史文脉 赓续盛世华章" },
        { title: "我们的价值观", description: "传承 创新 协同 共赢" }
      ]
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true; // 显示模态框
    },
    closeModal() {
      this.isModalVisible = false; // 隐藏模态框
      this.zoomLevel = 1; // 重置缩放级别
    },
    zoomIn() {
      this.zoomLevel += 0.1; // 放大图片
      this.updateZoom();
    },
    zoomOut() {
      this.zoomLevel = Math.max(0.1, this.zoomLevel - 0.1); // 缩小图片，限制最小值
      this.updateZoom();
    },
    zoomImage(event) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomIn(); // 向上滚轮放大
      } else {
        this.zoomOut(); // 向下滚轮缩小
      }
    },
    updateZoom() {
      const image = this.$refs.modalImage;
      image.style.transform = `scale(${this.zoomLevel})`; // 使用transform来实现缩放
      image.style.transition = 'transform 0.3s'; // 缩放时加上平滑过渡效果
    }
  }
};
</script>

<style scoped>
.about-section {
  padding: 60px 20px;
  background: #fff;
  color: #333;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  align-items: stretch;
  /* 确保图片和文字部分高度一致 */
}

.about-image-container {
  flex: 1;
  min-width: 100px;
  text-align: center;
}

.about-image {
  width: 100%;
  max-width: 500px;
  height: 100%;
  /* 让图片自适应容器高度 */
  object-fit: contain;
  /* 保持图片完整显示，避免裁切 */
  object-position: center;
  /* 图片居中对齐 */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.about-text {
  flex: 2;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  /* 确保文字垂直排列 */
  justify-content: space-between;
  /* 让文字均匀分布 */
}

.about-description {
  font-size: 1.2rem;
  margin-bottom: 0px;
  color: #555;
  text-align: justify;
  text-indent: 2em;
  word-wrap: break-word;
  /* 防止长单词超出屏幕 */
}

.about-scope1 {
  margin-top: 40px;
  padding: 30px;
  background: #f4f4f4;
  border-radius: 12px;
}

.about-scope {
  margin-top: 40px;
  padding: 30px;
  background: #f4f4f4;
  border-radius: 12px;
}

.scope-modules {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.scope-module {
  flex: 1 1 300px;
  min-width: 250px;
  max-width: 350px;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.scope-module h4 {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 10px;
}

.scope-module p {
  font-size: 0.9rem;
  color: #333;
}

.scope-module:hover {
  transform: translateY(-5px) scale(1.02);
}

.about-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #555;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content container */
.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: white;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Modal image */
.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* 确保图片等比例缩放 */
  object-position: center;
  /* 图片居中对齐 */
}

.zoom-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}

.zoom-controls button {
  color: #fff;
  background: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.zoom-controls button:hover {
  background: #444;
}
</style>
