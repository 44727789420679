import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.config.productionTip = false

// 导入组件视图
import About from './views/AboutPage.vue'
// 导入组件视图
//import Home from './views/Home.vue'
import Products from './views/Products.vue'
import ProjectIntroduction from './views/ProjectIntroduction.vue'

// 使用 vue-router 插件
Vue.use(VueRouter)
// 定义路由规则
const routes = [
  { path: '/', component: About },  // 默认重定向到 about
  { path: '/products', component: Products },
  { path: '/ProjectIntroduction', component: ProjectIntroduction }
]

// 创建路由实例
const router = new VueRouter({
  mode: 'history',  // 使用 HTML5 history 模式
  routes
})

// 创建 Vue 实例并挂载
new Vue({
  router,  // 挂载路由
  render: h => h(App)
}).$mount('#app')
