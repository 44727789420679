<template>
  <header class="header">
    <div class="logo">浙江盛世文脉科技有限公司</div>
    <nav class="nav" :class="{ open: isMenuOpen }">
      <ul>
        <li><router-link to="/" exact-active-class="active" @click="closeMenu">首页</router-link></li>
        <li><router-link to="/Products" exact-active-class="active" @click="closeMenu">业务矩阵</router-link></li>
        <!--        <li><router-link to="/team" exact-active-class="active">团队与部门</router-link></li>-->
        <li><router-link to="/ProjectIntroduction" exact-active-class="active" @click="closeMenu">经典案例</router-link></li>
      </ul>
    </nav>
    <!-- 汉堡菜单图标 -->
    <div class="hamburger" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false; // 点击导航项后关闭菜单
    },
  },
  watch: {
    // 监听路由变化，关闭菜单
    $route(to, from) {
      if (this.isMenuOpen) {
        this.closeMenu(); // 如果菜单打开，路由切换后关闭菜单
      }
    },
  },
};
</script>

<style scoped>
.header {
  background: #fff; /* 白色背景 */
  color: black; /* 黑色字体 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  color: black; /* 黑色 */
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin: 0 15px;
}

.nav ul li a {
  color: black; /* 黑色文字 */
  text-decoration: none;
  font-size: 18px;
  padding: 5px 10px;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #555; /* 灰色悬停效果 */
}

.nav ul li a.active {
  background-color: #f0f0f0; /* 浅灰色背景 */
  color: black;
  border-radius: 4px;
}

/* 移动端样式 */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  height: 2px;
  width: 25px;
  background: black;
  margin: 4px;
  transition: 0.3s;
}

/* 小屏幕样式 */
@media (max-width: 768px) {
  .logo {
    font-size: 20px;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .nav ul li {
    margin: 15px 0; /* 增加垂直方向的间距 */
  }

  .nav {
    position: absolute;
    top: 70px;
    right: 0;
    background: #fff; /* 白色背景 */
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .nav.open {
    max-height: 300px;
  }

  .hamburger {
    display: flex;
  }
}
</style>
